import React from 'react';

import CountUp from 'react-countup';

import { useInView } from 'react-intersection-observer';

import { motion } from 'framer-motion';

import { fadeIn } from '../variants';



const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <div className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          <motion.div
              variants={fadeIn('left', 0.3)}
              initial='hidden'
              whileInView={'show'}
              viewport={{once: false, amount: 0.3}}
              className='flex-1 text-center'>
            <h2 className='h2 text-accent'>A propos de moi</h2>
            <h3 className='h3 mb-4'>Je suis un étudiant en Informatique</h3>
            <p className='mb-6'>J'étudie en 3ème année de master à <span
                className='text-gradient'>EPITECH</span> Nantes, Je suis passionné par les nouvelles technologies et par
              l'informatique depuis mon plus jeune âge.
              Je suis à la <span className="text-2xl font-extrabold">recherche d'une alternance</span> dans le
              développement Full Stack pendant mon master.
              Mon intérêt pour les nouvelles technologies, associé à ma capacité à relever des défis de manière créative
              et méthodique, me prépare de la meilleure manière à exceller dans ce domaine
              Je suis déterminé à contribuer de manière significative au succès des projets informatiques.
            </p>
            <div className='lg:flex lg:gap-x-10 mb-12 justify-center'>
              <div className='hover:scale-110'>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={21} duration={2}/> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  ans
                </div>
              </div>
              <div className='hidden lg:flex gap-x-6 lg:gap-x-10 mb-12 justify-center'>
                <div className=' border-2 p-2 rounded-lg hover:scale-110'>
                  <p>Capacité d'adpatation</p>
                </div>
              </div>
              <div className='hidden lg:flex gap-x-6 lg:gap-x-10 mb-12 justify-center'>
                <div className='text-[20px] border-2 p-2 rounded-lg hover:scale-110'>
                  <p>Motivation</p>
                </div>
              </div>
              <div className='hidden lg:flex gap-x-6 lg:gap-x-10 mb-12 justify-center'>
                <div className='text-[20px] border-2 p-2 rounded-lg hover:scale-110'>
                  <p>Autonomie</p>
                </div>
              </div>
            </div>
            <div className='lg:flex flex-col lg:gap-x-10 mb-12 justify-center text-pretty'>
              <p className="text-2xl italic">"Je n'ai pas échoué. J'ai juste trouvé 10 000 façons qui ne fonctionnent pas".</p>
              <p className="">Thomas Edison</p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
)
};

export default About;