import React from 'react';
// components
import Banner from './components/Banner';
import Header from './components/Header';
import About from './components/About';
import Skills from './components/Skills';
import Work from './components/Work';
import Contact from './components/Contact';

const App = () => {
    return (
        <div className='bg-site bg-no-repeat bg-cover overflow-hidden overflow-y-auto'>
            <Header />
            <Banner />
            <About />
            <Skills />
            <Work />
            <Contact />
            <div className='lg:h-[26vh]'></div>
        </div>
    );
};

export default App;