import React from 'react';
import { Link } from 'react-scroll'; // Assure-toi d'installer react-scroll avec npm ou yarn

const Header = () => {
    return (
        <header className='py-4'>
            <div className='container mx-auto'>
                <div className='flex justify-end'>
                    <Link
                        to='contact'
                        smooth={true}
                        duration={1000}
                        className="btn-link"
                    >
                        <button className="btn btn-lg hover:scale-110 flex items-center justify-center">Contact</button>
                    </Link>
                </div>
            </div>
        </header>
    );
};

export default Header;
