import React from 'react';
import Image from '../assets/avatar2.png';
import CV from '../assets/CV.pdf';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
const Banner = () => {
  return (
  <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
    <div className='container mx-auto'>
      <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center'>
        <div className='flex-1 text-center font-secondary lg:text-left'>
          <motion.h1
          variants={fadeIn('up',0.3)}
          initial='hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.7}}
          className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>
            Alexandre <span> BENOIST</span>
            </motion.h1>
          <motion.div 
          variants={fadeIn('up',0.4)}
          initial='hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.7}}
          className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
            <span className=' text-whitemr-4'>Je suis </span>
            <TypeAnimation sequence={[
              'Developpeur',
              2000,
              'Etudiant',
              2000,
              'Passionné',
              2000,
            ]}
            speed={50}
            className='text-accent'
            wrapper='span'
            repeat={Infinity}
          />
          </motion.div>
          <motion.p 
          variants={fadeIn('up',0.5)}
          initial='hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.7}}
          className='mb-8 max-w-lg mx-auto lg:mx-0'>
            <div>
              <h3 className="text-3xl font-bold text-gradient">RECHERCHE D'ALTERNANCE FULLSTACK</h3>
            </div>
            <div>
              Bienvenue sur mon portfolio, ou vous pouvez en savoir plus sur moi.
              Vous souhaitant bonne naviguation !
            </div>
          </motion.p>
          <motion.div
          variants={fadeIn('up',0.7)}
          initial='hidden'
          whileInView={'show'}
          viewport={{once: false, amount: 0.7}}
           className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'>
            <a href={CV} target='_blank' className="btn btn-lg hover:scale-110 flex items-center justify-center">
              <button className="btn-link" >Mon CV</button>
            </a>
            <a href='https://www.linkedin.com/in/alexandre-benoist-2001b4223/' target='_blank' className='hover:scale-150 scale-125'>
              <FaLinkedin />
            </a>
            <a href='https://github.com/benoista' target='_blank' className='hover:scale-150 scale-125'>
              <FaGithub />
            </a>
          </motion.div>
        </div>
        <motion.div
        variants={fadeIn('down',0.3)}
        initial='hidden'
        whileInView={'show'}
        className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]'>
          <img src={Image} alt=''/>
        </motion.div>
      </div>
    </div>
  </section >);
};

export default Banner;
